/**
 * @file
 * Global classes (could be applied to any element)
 *
 */


// Visually hidden
.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding:0 !important;
  border:0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.btn {
  border: 0;
  border-radius: 35px 35px 35px 35px;
  -moz-border-radius: 35px 35px 35px 35px;
  box-shadow: 0;
  padding: 16px 24px;
  font-size: 1.6rem;
  font-family: Raleway-SemiBold;
  text-transform: uppercase;
  transition: box-shadow 300ms;
  cursor: pointer;
}

.link-internal {
  position: absolute;
  top: 20px;
  right: 0;
  color: $c-text-default;
  font-size: 1.4rem;
  text-transform: uppercase;
  background: url('#{$path-img}arrow-pink.png') no-repeat left center / auto 15px;
  height: 15px;
  padding-left: 30px;
  width: auto;

  @include respondTo('medium') {
    position: relative;
    bottom: 34px;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}