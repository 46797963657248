////
/// @file
/// COMPONENT breadcrumb
////

.breadcrumb {
  z-index: 3000;
  padding: 20px 10px;

  @include respondTo('medium') {
    padding: 20px;
  }

  .link {
    border: none;
    margin-bottom: 25px;
    margin-left: -30px;
    background-color: $c-white;

    li {
      display: inline-block;

      a {
        color: $c-green-900;
        font-family: $ff-light;
        font-size: 1.6rem;
        margin-bottom: 20px;
        height: 25px;
        width: 50px;
        padding-left: 30px;
        position: relative;

        @include icon($i-arrow, 1rem, after);

        &:after {
          color: $c-primary-A;
          position: absolute;
          top: 5px;
          right: -23px;
        }

        &.active {
          font-family: $ff-medium;
        }
      }

      &:last-child {
        a {
          &:after {
            content: unset;
          }
        }
      }

    }
  }

}
