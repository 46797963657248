////
/// @file
/// Ecedi theme color variables.
////

$c-green-100: #23d5c0;
$c-green-200: #1cc6bc;
$c-green-300: #39a4a1;
$c-green-500: #14a2b0;
$c-green-600: #1399a6;
$c-green-800: #026779;
$c-green-900: #014c54;

$c-orange-500: #fd5f61;
$c-orange-600: #dc545d;

$c-pink-500: #f4569b;

$c-purple-300: #74699a;
$c-purple-400: #362873;
$c-purple-500: #29215c;
$c-purple-900: #200d4e;

$c-grey-100: #a6a6a6;
$c-grey-200: #c6c6c6;
$c-grey-900: #333333;

$c-white: #ffffff;
$c-black: #000000;

$c-primary-A: $c-orange-500;
$c-primary-B: $c-green-200;
$c-primary-C: $c-purple-500;

$c-text-default: $c-grey-900;
