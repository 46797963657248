////
/// @file
/// FOOTER
////



footer.footer {
  background-color: #F6F0F0;
  padding-left: calc((100vw - 1340px) / 2);
  padding-right: calc((100vw - 1340px) / 2);
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @include respondTo('medium') {
    flex-direction: column;
  }

  @include respondTo('medium up') {
    padding-top: 30px;
    align-items: flex-start;
  }

  ul {
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;

    @include respondTo('medium') {
      width: 100%;
    }

    > li {
      list-style: none;

      &:last-of-type a:after {
        display: none;
      }
      & img {
        width: 100%;
      }
    }
  }

  .links {

    @include respondTo('small') {
      flex-direction: column;
    }

    @include respondTo('medium up') {
      margin-top: 20px;
    }

    li {

      @include respondTo('medium') {
        margin-bottom: 15px;
      }

      @include respondTo('small') {
        flex-direction: column;
      }
    }

    a {
      font-size: 1.2rem;
      padding: 0 20px;
      position: relative;
      white-space: nowrap;
      color: $c-text-default;

      &::after {
        content: '/';
        font-size: 2rem;
        color: $c-primary-A;
        font-family: $ff-title;
        position: absolute;
        top: -5px;
        right: -10px;

        @include respondTo('small') {
          content: none;
        }
      }
    }
  }

  .ctrla {
    margin-top: 15px;
    text-align: center;
    padding: 0 20px;
    margin-bottom: 10px;

    @include respondTo('medium up') {
      margin-bottom: 0;
      padding: 0 0 0 20px;
      text-align: left;
    }

    img {
      @include respondTo('medium up') {
        margin-left: -3px;
      }
    }

    p {
      margin-top: 5px;
      margin-bottom: 0;
      color: $c-text-default;
      font-size: 1.2rem;

      a {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  .partners-rs {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respondTo('medium up') {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .social {
    li {
      height: 60px;
      width: 60px;
      margin-right: 15px;

      &:last-child {
        margin-right: 20px;

        @include respondTo('medium') {
        margin-right: 0;
        }
      }
    }

    a {
      display: inline-block;
      height: 60px;
      width: 60px;
      background: $c-green-600;
      border-radius: 50%;
      color: transparent;
      position: relative;
      transition: $transition;

      &:before {
        color: $c-white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      &:after {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &:hover,
      &:focus,
      &:active {
        transform: scale(1.2);
        background-color: $c-primary-B;
      }

      &.twitter {
        @include icon($i-twitter, 3rem) {

        }
      ;
      }

      &.facebook {
        @include icon($i-facebook, 3rem) {

        }
      ;
      }

      &.linkedin {
        @include icon($i-linkedin, 3rem) {
        }
      ;
      }
    }
  }
}