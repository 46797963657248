////
/// @file
/// Triangle for cool effects
////

.triangle {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0.5;

  &.top {
    border-left: 300px solid transparent;
    border-right: 300px solid transparent;
    border-bottom: 540px solid transparent;

    &.small {
      border-left-width: 50px;
      border-right-width: 50px;
      border-bottom-width: 85px;
    }

    &.green {
      border-bottom-color: $c-green-200;
    }

    &.purple {
      border-bottom-color: $c-purple-400;
    }

    &.white {
      border-bottom-color: $c-white;
    }
  }

  &.down {
    border-left: 300px solid transparent;
    border-right: 300px solid transparent;
    border-top: 540px solid transparent;

    &.small {
      border-left-width: 50px;
      border-right-width: 50px;
      border-top-width: 85px;
    }

    &.green {
      border-top-color: $c-green-200;
    }

    &.purple {
      border-top-color: $c-purple-400;
    }

    &.white {
      border-top-color: $c-white;
    }
  }

}

@keyframes floating1 {
  0% {
    transform: scale(1.2) translateY(20px);
  }

  100% {
    transform: scale(0.9) translateY(-20px);
  }
}


@keyframes floating2 {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: scale(0.8) translateY(20px);
  }
}

