////
/// @file
/// HEADER : Header custom styles
////

#header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $c-green-100 url("#{$path-img}e.png") no-repeat -150px center;
  background-size: 60%;
  height: 625px;
  text-align: center;
  color: $c-green-900;
  position: relative;


  @include respondTo('small') {
    background-size: 120%;
    padding-top: 0;
    font-size: 80%;
  }

  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
  p {
    font-size: 1.5em;
    margin-top: 200px;
    @include respondTo('small') {
      margin-top: 30%;

    }
  }

  h1 {
    font-family: $ff-light;
    font-size: 2.4rem;
    line-height: 1.9;
    margin-top: 0;
    margin-bottom: 50px;
    @include respondTo('small') {
      font-size: 1.5em;
      margin-top: 0;
      line-height: normal;

    }
    .title {
      font-family: $ff-title;
      font-size: 5rem;
      @include respondTo('small') {
        font-size: 1.8em;

      }
    }
  }

  .home-logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .header-highlight {
    @include respondTo('small') {
      bottom: 50px;
    }
  }

  .btn {
    @include btnColor($c-green-500, $c-white);
    &:hover,
    &:focus {
      background: $c-green-900;
      -webkit-transition: background-color 300ms linear;
      -moz-transition: background-color 300ms linear;
      -o-transition: background-color 3000ms linear;
      -ms-transition: background-color 300ms linear;
      transition: background-color 300ms linear;
      color: $c-white;
      text-decoration: none;
    }
  }

  .fa-download {
    margin-right: 10px;
  }
  .hero-header {

    .actionbutton {
      position: relative;
      bottom: 20px;
      overflow: hidden;
    }
    .row {
      margin: 0;
    }
  }

}
