////
/// @file
/// COMPONENT : proud
////



.section-proud {

  .wrapper {
    margin-bottom: 80px;
  }

  .slider {

    .slide-item a {
      position: relative;

    }

    .slick-slide {
      margin-right: 20px;
      height: auto;
      &:not(.slick-active) img {
        opacity: 0.5;
      }
    }
    & ul.slick-dots {
      li {
        display: none;
      }
    }
    .slick-arrow {
      width: 50px;
      height: 50px;
      background: url("#{$path-img}arrow-pink.png") no-repeat center center;
      border: none;
      margin: 0;
      padding: 0;
      cursor: pointer;

      &.slick-prev {
        transform: rotateZ(180deg);
        position: absolute;
        bottom: -100px;
        left: 0;
        overflow: hidden;

        & span {
          position: absolute;
          left: -9999px;
          overflow: hidden,
        }
      }
      &.slick-next {
        position: absolute;
        bottom: -100px;
        right: 0;
        overflow: hidden;
        & span {
          position: absolute;
          right: -9999px;
        }
      }
    }
  }

  .pager {
    display: inline-block;
    position: absolute;
    @extend %absoluteLeft;

    .currentSlide,
    .slideCount {
      display: inline-block;
      padding: 0 20px;
      margin: 60px 0 0;
    }
    .currentSlide {
      position: relative;
      &:after {
        content: '/';
        font-size: 3rem;
        color: $c-primary-A;
        font-family: $ff-title;
        position: absolute;
        top: 50%;
        right: -15px;
        transform: translateY(-50%);
      }
    }
  }

  .link-internal {
    @include respondTo('medium') {
      top: -25px;
    }
  }
}

