////
/// @file
/// Define all extendables.
////

/// Base for an icon
%icon {
  font-family: $ff-icon;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
}

/// Resets link (<a>) styles.
%resetLink {
  display: block;
  color: inherit;
  text-decoration: none;
}

/// Resets button (<button>, <input>) styles.
%resetBtn {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  border-radius: 0;
  background: none;
  color: inherit;
  text-align: inherit;
  white-space: normal;
  box-shadow: none;
}

/// Sets full width for an image.
%fullImg {
  display: block;
  width: 100%;
  height: auto;
}

/// Align center on absolute position
%absoluteLeft {
  left: 50%;
  transform: translateX(-50%);
}

/// RS links
%highlight {
  color: $c-primary-A;
  font-family: "Raleway-Bold";
}