////
/// @file
/// COMPONENT : Go top button
////

.gotop-row {
  background: transparent;
  margin: -71px 0 0 0;
  width: auto ;
  z-index: 1000;
  position: relative;
}
.gotop-button {
  position: relative;
  overflow: hidden;
  display: block;
  width: 70px;
  height: 70px;
  margin-left: calc(50% - 35px);
  border-radius: 50%;
  background: $c-white url('#{$path-img}arrow-pink.png') no-repeat center center / auto 26px;
  color: $c-primary-A;
  transform: rotateZ(-90deg) translateX(-35px);

  .hidden-text {
    position: absolute;
    right: 100%;
  }
}
