////
/// @file
/// Define all mixins.
////

/// Media query definition.
/// @param {string} $screen - The breakpoint name. Must be a key of the $breakpoints map.
/// @example
///   .foo {
///     @include respondTo('small') {
///       color: #424242;
///     }
///   }
@mixin respondTo($screen) {

  $media: mediaMapJoin(map-get($breakpoints, $screen));

  @if $media {
    @media screen and #{$media} {
      @content;
    }
  }
  @else {
    @error 'No "#{$screen}" breakpoint found in $breakpoint';
  }

}



/// Display icon using the before|after pseudo element.
/// @param {string} $icon - The icon content code (eg. from icomoon).
/// @param {length | percentage} $size - The icon size in rem|em|px|etc...
/// @param {string} $pseudoElement - The pseudo element to apply the icon to (before|after).
/// @example
///    .submit-button {
///      @include icon($i-submit);
///      // OR with custom content.
///      @include icon($i-submit) {
///        // add some specific CSS properties.
///      }
///    }
@mixin icon($icon, $size: 2.2rem, $pseudoElement: before) {

  &::#{$pseudoElement} {
    @extend %icon;
    display: inline-block;
    content: '#{$icon}';
    font-size: $size;
    @content;
  }

}

/// @font-face definitions.
/// @param {string} $name - The font-family name.
/// @param {url} $path - The files path (without the extension).
/// @param {string} $weight - The associated font-weight (normal | bold | bolder | lighter | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900).
/// @param {string} $style - The associated font-style (normal | italic | oblique).
/// @param {list} $exts - The associated extensions.
/// @example
///    @include font-face(MyFont, "../font/my-font", bold, italic, eot woff ttf svg);
@mixin fontFace($name, $path, $weight: normal, $style: normal, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: "?#iefix",
    svg: "#" + strReplace($name, " ", "_")
  );

  $formats: (
    eot: "embedded-opentype",
    otf: "opentype",
    ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    src: $src , $src;
    font-style: $style;
    font-weight: $weight;
  }
}

/// TODO possible rewrite of this mixin.
@mixin bulletedList($color, $size: 6) {
  &::before {
    width: #{$size}px;
    height: #{$size}px;
    margin-right: 6px;
    position: relative;
    top: -1px;
    display: inline-block;
    content: '';
    border-radius: 50%;
    background: $color;
  }
}

/// Defines button colors, on default state & on hover/focus : you should adapt it to your needs in the project.
/// @param {string} $bg - The background color.
/// @param {string} $color - The text color.
/// @param {string} $border - The border color (optional).
/// @example
///   .foo {
///     @include btnColor($c-primary-A, $c-primary-B);
///   }

@mixin btnColor($bg: $c-primary-A, $color: $c-white, $border: null) {
  background: $bg;
  color: $color;
  @if $border {
    border-color: $border;
  }

  &:hover,
  &:focus {
    background: $color;
    color: $bg;
    transition: $transition;
    @if $border {
      border-color: $bg;
    }
  }
}

// ---------------------------------
// ------------- Separators -------------
// ---------------------------------

@mixin separator($color, $height, $margin) {
  &:after {
    content: '';
    background-color: $color;
    display: block;
    width: 50px;
    height: $height;
    margin: $margin;
    position: absolute;
    bottom: -50px;
  }
}
