////
/// @file
/// COMPONENT : expert
////

.section-expert {

  .skills-item {
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    padding: 0;
    flex-wrap: wrap;

    .item {
      display: block;
      list-style-type: none;
      text-align: center;
      padding: 150px 12px 12px;
      width:210px;
      margin: 20px 5px 40px 5px;
      position: relative;

      @include respondTo('small') {
        width: 300px;
      }

      h3 {
      color: $c-green-300;
      font-weight: bold;
      font-size: 2.4rem;
      margin-bottom: 20px;
      }

      p {
        font-size: 1.6rem;
        line-height: 24px;
      }

      &:before {
        color: $c-primary-B;
        font-size: 13rem !important; // overwrite mixin icon() default font size
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &.strategy {
        @include icon($i-strategy);
      }
      &.conception {
        @include icon($i-conception);
      }
      &.production {
        @include icon($i-production);
      }
      &.run {
        @include icon($i-run);
      }
      &.quality {
        @include icon($i-quality);
      }
    }
  }

}
.logo-techno {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;

  @include respondTo('medium') {
    display: block;
  }
}
.logo-tech1,
.logo-tech2 {
  width: 49%;
  @include respondTo('medium') {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
}
.slash {
  content: '';
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 8px;
  height: 100px;
  border: none;
  -o-text-overflow: clip;
  text-overflow: clip;
  background: $c-grey-100;
  -webkit-transform: skewX(-20deg) translate(-25px, 8%);
  transform: skewX(-20deg) translate(-25px, 8%);
  margin-right: 0;
  @include respondTo('medium') {
    display: none
  }
}
.tech1,
.tech2,
.tech3,
.tech4 {
  display: flex;
  align-items: center;
  justify-content: center;
  @include respondTo('medium') {
    display: inline-block;
    text-align: center;
  }

  img {
    margin: 15px;
  }
}
