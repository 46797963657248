////
/// @file
/// COMPONENT : connected
////



.section-connected {
  background: $c-purple-400 url("#{$path-img}bg-connected.jpg") no-repeat center;
  background-size: cover;
  color: $c-white;

  @include respondTo('medium') {
    padding: 50px 10px;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;

    @include respondTo('medium') {
      flex-direction: column;
    }
  }

  .title-area {
    width: 50%;

    @include respondTo('medium') {
      margin-top: 20px;
      width: 100%;
    }
  }

  .api-box {
    display: none;
    left: 0;
    right: 0;
    color: $c-text-default;
    max-width: 545px;
    padding: 80px;
    min-height: 545px;
    background-color: $c-white;

    &.active {
      display: block;
    }

    &.facebook {
      .api-top {
        background: url('#{$path-img}bg-facebook.png') -9px -4px no-repeat;
      }
    }
    &.twitter {
      .api-top {
        background: url('#{$path-img}bg-twitter.png') no-repeat;
      }
    }
    &.linkedin {
      .api-top {
        background: url('#{$path-img}bg-linkedin.png') -8px -5px no-repeat;
      }
    }

    a {
      @extend %highlight;
    }

    .active{
      z-index:4 !important;
    }

    @include respondTo('small') {
      background-position: 5px 5px;
    }

    @include respondTo('medium') {
      max-width: 100%;
      padding: 20px;
    }

  }

  .api-top {
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 90px;
    margin-bottom: 45px;
    line-height: 1;
    font-size: 1.4rem;

    p {
      margin: 0;
    }
  }

  ul {
    bottom:-710px;

    li {
      width: 50px;
      height: 50px;
      display: inline;
      list-style: none;
      border: none;
      margin: 0;
      padding: 0;
      cursor: pointer;

      a {
        font-family: $ff-base;
        font-size: 2em;
      }
    }

  }

  .api-switch {
    width: 100%;
    margin-top: 30px;

    .api-button {
      display: inline-block;
      height: 25px;
      width: 25px;
      position: relative;
      margin: 0 20px;

      &:after {
        color: $c-purple-300;
        position: absolute;
        top: 0;
      }

      &:hover,
      &:active,
      &:focus {
        color: $c-purple-300;
        &:after {
          color: $c-purple-300;
        }
      }

      &.active {
        transition: all ease .5s;
        &:after {
          color: $c-white;
          border-bottom: 5px solid;
          height: 45px;
        }
      }

      
      &:before {
        content: '/';
        display: inline-block;
        color: $c-primary-A;
        font-family: Raleway-Black;
        font-size: 2.5rem;
        position: absolute;
        top: 0;
        left: -35px;
      }

      &.twitter-api {
        @include icon($i-twitter, 2.5rem, after){
          left: 0;
          right: 0;
        }
      }

      &.facebook-api {
        @include icon($i-facebook, 2.5rem, after){
          left: 0;
          right: 0;
        }
        &::before {
          display: none;
        }
      }

      &.linkedin-api {
        @include icon($i-linkedin, 2.5rem, after){
          left: 0;
          right: 0;
        }
      }
    }


    .list {
      right: 0;
      padding-left: 0;
      text-align: center;

      //@include respondTo('small up') {
      //  width: 570px;
      //}

    }
  }
}

