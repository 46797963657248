////
/// @file
/// COMPONENT : form
////
#contact {

  form {

    input,
    textarea,
    select {
      width: 100%;
      background: transparent;
      border: solid $c-white 2px;
      height: 60px;
      padding-left: 15px;
    }

    button[type="submit"] {
      @include respondTo('xs') {
        clear: both;
        width: 100%;
      }
    }

    select {
      border: solid 2px $c-white;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      background: url("#{$path-img}arrow-down.png") no-repeat right 20px center;
      background-size: 20px;
    }

    label {
      font-weight: normal;
    }

    input {
      min-height: 100%;
      color: $c-white;
    }

    textarea {
      min-height: 240px;
      max-width: 100%;
      min-width: 100%;
      padding-top: 25px;
      color: $c-white;
      margin-bottom: 20px;
    }

    .form-input[name="prenom"],
    .form-input[name="email"],
    select[name="wishlist"] {
      border-bottom: none;
    }

    select[name="wishlist"] {
      background-color: $c-primary-A;
      border-radius: 0;
      color: $c-white;
      font-size: 1.8rem;

      option {
        padding: 0 20px;
        color: $c-white;

        &:first-child {
          visibility: hidden;
        }
      }
    }

    .field-form-item {
      height: 60px;
    }
  }

  input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px $c-white inset;
    -webkit-text-fill-color: black;
  }

  label.error {
    position: relative;
    left: -9999px;
  }

  .field-form-item.error,
  .field-form-textarea.error {
    position: relative;
  }

  .field-form-item.valid {
    position: relative;
    &:after {
      animation: fadein 2s;
      content: "\e807";
      color: $c-primary-B;
      font-family: 'fontello';
      position: absolute;
      top: 15px;
      right: 8px;
      //.font-size(3.4);
      z-index: 10;
    }
  }

  @-moz-document url-prefix() {
    .form-input {
      &:focus {
        outline-offset: -2px;
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .form-input {
      &:focus {
        border: 2px $c-primary-B solid;
        outline: 2px transparent;
      }
    }
  }

  @supports (-ms-accelerator:true) {
    .form-input {
      &:focus {
        border: 2px $c-primary-B solid;
        outline: 2px transparent;
      }
    }
  }

  .printArea {
    display: none;
    color: $c-white;
  }

  .printArea.print-appear {
    display: block;
  }

  #form.form-disappear {
    display: none;
  }

  form .loader {
    display: none;
    float: right;
    font-size: 3em;
    color: $c-primary-B;
    margin-top: 13px;
    margin-right: 13px;
  }

  form input[name="valide_submit"] {
    display: none;
  }

  .triangle {
    opacity: 0.15;
    transform: rotateZ(45deg);

    &.purple {
      top: -50px;
      right: -40px;
    }

    &.purple {
      right: 264px;
      top: 250px;
      animation: floating1 2s alternate infinite ease-in-out;
    }

    &.mid1 {
      right: 300px;
      top: 133px;
      animation: floating2 2.5s alternate infinite ease-in-out;
    }

    &.mid2 {
      right: 400px;
      top: 166px;
      animation: floating2 3s alternate infinite ease-in-out;
    }

    &.last {
      right: 227px;
      top: 200px;
      animation: floating2 3.5s alternate infinite ease-in-out;
    }
  }

  .link {
    color: inherit;
    line-height: 1.2em;
    text-decoration: underline;

    // div recaptcha
    & + div {
      display: inline-block;
    }

  }
}
