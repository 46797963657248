////
/// @file
/// COMPONENT menu
////


#menu {
  z-index: 3000;
  padding: 0;
  background-color: $c-white;
  border-top: 1px solid $c-grey-200;
  border-bottom: 1px solid $c-grey-200;

  img {
    position: relative;
    top: -32px;
    left: 48px;
  }

  //sticky scroll menu
  &.navbar-fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3000;

    .navbar {
      margin-bottom: 0;
    }

    .menu > li > a {
      @include respondTo('medium up') {
        padding: 5px 10px;
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: column;

    @include respondTo('medium up') {
      width: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    > li {

      @include respondTo('medium') {
        width: 100%;
        margin: 10px 20px;
        text-align: left;
        background-color: transparent;
      }

      > a {
        color: $c-text-default;
        line-height: 40px;
        font-family: $ff-light;
        font-size: 1.8rem;
        position: relative;
        padding: 12px 2px;
        transition: none;
        transition: padding .1s ease-out;

        @include respondTo('medium up') {
          display: block;
          padding: 17px 10px;
          margin: 0 30px;
        }

        &::before {
          content: '/';
          font-size: 3rem;
          color: $c-primary-A;
          font-family: $ff-title;
          position: absolute;
          left: -40px;

          @include respondTo('medium') {
            display: none;
          }
        }

        &::after {
          width: 0;
          height: 5px;
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          display: block;
          background-color: $c-primary-A;
          transition: width .2s ease-in;

          @include respondTo('medium up') {
            bottom: -1px;
          }
        }

        &:hover,
        &:focus,
        &.active {
          position: relative;

          &::after {
            width: 100%;
          }
        }
      }

      // Remove first pipe
      &:first-of-type > a:before {
        display: none;
      }
    }
  }

  button.navbar {
    position: relative;
    top: 20px;
    left: 20px;
    margin: 0 0 32px 0;
    padding: 0;
    overflow: visible;
    background-color: transparent;
    border: none;

    > .icon-bar {
      display: block;
      background-color: #00E1C2;
      width: 30px;
      height: 4px;
      margin-bottom: 4px;
    }
    @include respondTo('medium up') {
      display: none;
    }
  }

  @include respondTo('medium') {
    .main-navigation {
      max-height: 360px;
      overflow: hidden;
      //transition: max-height .25s ease-in-out;

      &.collapsed {
        max-height: 0;
      }
    }
  }
}

#menu button.navbar {
  margin-bottom: 0;
}
