@charset "UTF-8";
.legal-text li::before, .breadcrumb .link li a::after, footer.footer .social a.twitter::before, footer.footer .social a.facebook::before, footer.footer .social a.linkedin::before, .section-connected .api-switch .api-button.twitter-api::after, .section-connected .api-switch .api-button.facebook-api::after, .section-connected .api-switch .api-button.linkedin-api::after, .section-expert .skills-item .item.strategy::before, .section-expert .skills-item .item.conception::before, .section-expert .skills-item .item.production::before, .section-expert .skills-item .item.run::before, .section-expert .skills-item .item.quality::before, .section-join-us .recruitment-wrapper .item .offre::before {
  font-family: "icomoon", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle; }

.map .city button {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  border-radius: 0;
  background: none;
  color: inherit;
  text-align: inherit;
  white-space: normal;
  box-shadow: none; }

.section-proud .pager {
  left: 50%;
  transform: translateX(-50%); }

.section-connected .api-box a {
  color: #fd5f61;
  font-family: "Raleway-Bold"; }

/**
 * @file
 * Global classes (could be applied to any element)
 *
 */
.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden; }

.btn {
  border: 0;
  border-radius: 35px 35px 35px 35px;
  -moz-border-radius: 35px 35px 35px 35px;
  box-shadow: 0;
  padding: 16px 24px;
  font-size: 1.6rem;
  font-family: Raleway-SemiBold;
  text-transform: uppercase;
  transition: box-shadow 300ms;
  cursor: pointer; }

.link-internal {
  position: absolute;
  top: 20px;
  right: 0;
  color: #333333;
  font-size: 1.4rem;
  text-transform: uppercase;
  background: url("../images/arrow-pink.png") no-repeat left center/auto 15px;
  height: 15px;
  padding-left: 30px;
  width: auto; }
  @media screen and (max-width: 1024px) {
    .link-internal {
      position: relative;
      bottom: 34px; } }
  .link-internal:hover, .link-internal:focus {
    text-decoration: underline; }

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?#iefix") format("embedded-opentype"), url("../fonts/icomoon.woff") format("woff"), url("../fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon.svg#icomoon") format("svg"), url("../fonts/icomoon.eot?#iefix") format("embedded-opentype"), url("../fonts/icomoon.woff") format("woff"), url("../fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon.svg#icomoon") format("svg");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "Raleway-Black";
  src: url("../fonts/Raleway-Black.ttf") format("truetype"), url("../fonts/Raleway-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "Raleway-Bold";
  src: url("../fonts/Raleway-Bold.ttf") format("truetype"), url("../fonts/Raleway-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "Raleway-SemiBold";
  src: url("../fonts/Raleway-SemiBold.ttf") format("truetype"), url("../fonts/Raleway-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "Raleway-Medium";
  src: url("../fonts/Raleway-Medium.ttf") format("truetype"), url("../fonts/Raleway-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "Raleway-Regular";
  src: url("../fonts/Raleway-Regular.ttf") format("truetype"), url("../fonts/Raleway-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "Raleway-Italic";
  src: url("../fonts/Raleway-Italic.ttf") format("truetype"), url("../fonts/Raleway-Italic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "Raleway-Light";
  src: url("../fonts/Raleway-Light.ttf") format("truetype"), url("../fonts/Raleway-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html {
  font-size: 0.625em;
  box-sizing: border-box; }

body {
  font-family: Raleway-Regular, Arial, sans-serif;
  font-size: 1.6rem;
  margin: 0;
  overflow-x: hidden; }

h1,
h2,
h3 {
  font-family: Raleway-Black, Arial, sans-serif;
  font-weight: bold; }

h2 {
  font-size: 3.2rem; }

h3 {
  font-size: 2.8rem; }

a {
  text-decoration: none;
  transition: all 0.4s ease-in-out; }

ul,
ol,
li {
  padding: 0;
  margin: 0; }

li {
  list-style: none; }

section {
  position: relative;
  padding: 50px 20px;
  min-height: 745px; }

button {
  font-family: inherit;
  cursor: pointer; }

main {
  overflow: hidden; }
  main.navbar-fixed {
    margin-top: 5.5rem; }

.wrapper {
  max-width: 1235px;
  margin: 0 auto;
  position: relative; }

.main-content h2 {
  text-transform: uppercase; }

.type-page {
  position: relative; }
  .type-page .triangle {
    opacity: .1; }
    .type-page .triangle.green {
      animation: floating1 2s alternate infinite ease-in-out; }
      .type-page .triangle.green.last {
        animation: floating2 3s alternate infinite ease-in-out; }
    .type-page .triangle.top-right.big {
      top: 150px;
      right: -33%; }
    .type-page .triangle.top-right.small {
      top: 400px;
      right: 5%; }
    .type-page .triangle.top-right.last {
      top: 420px;
      right: 0; }
    .type-page .triangle.bottom-right.small {
      right: -7%;
      bottom: 50px; }
    .type-page .triangle.bottom-right.last {
      right: -10%;
      bottom: 65px; }
    .type-page .triangle.bottom-left.small {
      top: 48%;
      left: -10%; }
    .type-page .triangle.bottom-left.last {
      top: 50%;
      left: -5%; }

.legal-text .title-area {
  margin-bottom: 60px;
  margin-top: 60px; }

.legal-text h2 a, .legal-text a {
  color: #fd5f61; }

.legal-text li {
  margin-left: 3em;
  margin-bottom: 12px; }
  .legal-text li::before {
    display: inline-block;
    content: "";
    font-size: 1rem;
    color: #fd5f61;
    margin-right: 5px; }

.legal-text {
  margin-bottom: 150px;
  padding-right: 9%;
  padding-left: 9%;
  position: relative; }
  @media screen and (min-width: 1025px) {
    .legal-text {
      max-width: 930px;
      padding-right: 0; } }

.cookie-item {
  margin-bottom: 25px; }
  .cookie-item p {
    margin: 0; }

.legal-notices-matomo-opt-out > p:first-of-type {
  display: none; }

.legal-notices-matomo-opt-out strong {
  font-weight: 400; }

.breadcrumb {
  z-index: 3000;
  padding: 20px 10px; }
  @media screen and (max-width: 1024px) {
    .breadcrumb {
      padding: 20px; } }
  .breadcrumb .link {
    border: none;
    margin-bottom: 25px;
    margin-left: -30px;
    background-color: #ffffff; }
    .breadcrumb .link li {
      display: inline-block; }
      .breadcrumb .link li a {
        color: #014c54;
        font-family: Raleway-Light, Arial, sans-serif;
        font-size: 1.6rem;
        margin-bottom: 20px;
        height: 25px;
        width: 50px;
        padding-left: 30px;
        position: relative; }
        .breadcrumb .link li a::after {
          display: inline-block;
          content: "";
          font-size: 1rem; }
        .breadcrumb .link li a:after {
          color: #fd5f61;
          position: absolute;
          top: 5px;
          right: -23px; }
        .breadcrumb .link li a.active {
          font-family: Raleway-Medium, Arial, sans-serif; }
      .breadcrumb .link li:last-child a:after {
        content: unset; }

footer.footer {
  background-color: #F6F0F0;
  padding-left: calc((100vw - 1340px) / 2);
  padding-right: calc((100vw - 1340px) / 2);
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center; }
  @media screen and (max-width: 1024px) {
    footer.footer {
      flex-direction: column; } }
  @media screen and (min-width: 1025px) {
    footer.footer {
      padding-top: 30px;
      align-items: flex-start; } }
  footer.footer ul {
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center; }
    @media screen and (max-width: 1024px) {
      footer.footer ul {
        width: 100%; } }
    footer.footer ul > li {
      list-style: none; }
      footer.footer ul > li:last-of-type a:after {
        display: none; }
      footer.footer ul > li img {
        width: 100%; }
  @media screen and (max-width: 767px) {
    footer.footer .links {
      flex-direction: column; } }
  @media screen and (min-width: 1025px) {
    footer.footer .links {
      margin-top: 20px; } }
  @media screen and (max-width: 1024px) {
    footer.footer .links li {
      margin-bottom: 15px; } }
  @media screen and (max-width: 767px) {
    footer.footer .links li {
      flex-direction: column; } }
  footer.footer .links a {
    font-size: 1.2rem;
    padding: 0 20px;
    position: relative;
    white-space: nowrap;
    color: #333333; }
    footer.footer .links a::after {
      content: '/';
      font-size: 2rem;
      color: #fd5f61;
      font-family: Raleway-Black, Arial, sans-serif;
      position: absolute;
      top: -5px;
      right: -10px; }
      @media screen and (max-width: 767px) {
        footer.footer .links a::after {
          content: none; } }
  footer.footer .ctrla {
    margin-top: 15px;
    text-align: center;
    padding: 0 20px;
    margin-bottom: 10px; }
    @media screen and (min-width: 1025px) {
      footer.footer .ctrla {
        margin-bottom: 0;
        padding: 0 0 0 20px;
        text-align: left; } }
    @media screen and (min-width: 1025px) {
      footer.footer .ctrla img {
        margin-left: -3px; } }
    footer.footer .ctrla p {
      margin-top: 5px;
      margin-bottom: 0;
      color: #333333;
      font-size: 1.2rem; }
      footer.footer .ctrla p a {
        color: inherit;
        text-decoration: underline; }
        footer.footer .ctrla p a:hover, footer.footer .ctrla p a:focus {
          text-decoration: none; }
  footer.footer .partners-rs {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media screen and (min-width: 1025px) {
      footer.footer .partners-rs {
        display: flex;
        flex-direction: row;
        align-items: center; } }
  footer.footer .social li {
    height: 60px;
    width: 60px;
    margin-right: 15px; }
    footer.footer .social li:last-child {
      margin-right: 20px; }
      @media screen and (max-width: 1024px) {
        footer.footer .social li:last-child {
          margin-right: 0; } }
  footer.footer .social a {
    display: inline-block;
    height: 60px;
    width: 60px;
    background: #1399a6;
    border-radius: 50%;
    color: transparent;
    position: relative;
    transition: all 0.4s ease-in-out; }
    footer.footer .social a:before {
      color: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%); }
    footer.footer .social a:after {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    footer.footer .social a:hover, footer.footer .social a:focus, footer.footer .social a:active {
      transform: scale(1.2);
      background-color: #1cc6bc; }
    footer.footer .social a.twitter::before {
      display: inline-block;
      content: "";
      font-size: 3rem; }
    footer.footer .social a.facebook::before {
      display: inline-block;
      content: "";
      font-size: 3rem; }
    footer.footer .social a.linkedin::before {
      display: inline-block;
      content: "";
      font-size: 3rem; }

#contact form input,
#contact form textarea,
#contact form select {
  width: 100%;
  background: transparent;
  border: solid #ffffff 2px;
  height: 60px;
  padding-left: 15px; }

@media screen and (max-width: 480px) {
  #contact form button[type="submit"] {
    clear: both;
    width: 100%; } }

#contact form select {
  border: solid 2px #ffffff;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background: url("../images/arrow-down.png") no-repeat right 20px center;
  background-size: 20px; }

#contact form label {
  font-weight: normal; }

#contact form input {
  min-height: 100%;
  color: #ffffff; }

#contact form textarea {
  min-height: 240px;
  max-width: 100%;
  min-width: 100%;
  padding-top: 25px;
  color: #ffffff;
  margin-bottom: 20px; }

#contact form .form-input[name="prenom"],
#contact form .form-input[name="email"],
#contact form select[name="wishlist"] {
  border-bottom: none; }

#contact form select[name="wishlist"] {
  background-color: #fd5f61;
  border-radius: 0;
  color: #ffffff;
  font-size: 1.8rem; }
  #contact form select[name="wishlist"] option {
    padding: 0 20px;
    color: #ffffff; }
    #contact form select[name="wishlist"] option:first-child {
      visibility: hidden; }

#contact form .field-form-item {
  height: 60px; }

#contact input:-webkit-autofill, #contact textarea:-webkit-autofill, #contact select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
  -webkit-text-fill-color: black; }

#contact label.error {
  position: relative;
  left: -9999px; }

#contact .field-form-item.error,
#contact .field-form-textarea.error {
  position: relative; }

#contact .field-form-item.valid {
  position: relative; }
  #contact .field-form-item.valid:after {
    animation: fadein 2s;
    content: "\e807";
    color: #1cc6bc;
    font-family: 'fontello';
    position: absolute;
    top: 15px;
    right: 8px;
    z-index: 10; }

@-moz-document url-prefix() {
  #contact .form-input:focus {
    outline-offset: -2px; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #contact .form-input:focus {
    border: 2px #1cc6bc solid;
    outline: 2px transparent; } }

@supports (-ms-accelerator: true) {
  #contact .form-input:focus {
    border: 2px #1cc6bc solid;
    outline: 2px transparent; } }

#contact .printArea {
  display: none;
  color: #ffffff; }

#contact .printArea.print-appear {
  display: block; }

#contact #form.form-disappear {
  display: none; }

#contact form .loader {
  display: none;
  float: right;
  font-size: 3em;
  color: #1cc6bc;
  margin-top: 13px;
  margin-right: 13px; }

#contact form input[name="valide_submit"] {
  display: none; }

#contact .triangle {
  opacity: 0.15;
  transform: rotateZ(45deg); }
  #contact .triangle.purple {
    top: -50px;
    right: -40px; }
  #contact .triangle.purple {
    right: 264px;
    top: 250px;
    animation: floating1 2s alternate infinite ease-in-out; }
  #contact .triangle.mid1 {
    right: 300px;
    top: 133px;
    animation: floating2 2.5s alternate infinite ease-in-out; }
  #contact .triangle.mid2 {
    right: 400px;
    top: 166px;
    animation: floating2 3s alternate infinite ease-in-out; }
  #contact .triangle.last {
    right: 227px;
    top: 200px;
    animation: floating2 3.5s alternate infinite ease-in-out; }

#contact .link {
  color: inherit;
  line-height: 1.2em;
  text-decoration: underline; }
  #contact .link + div {
    display: inline-block; }

.gotop-row {
  background: transparent;
  margin: -71px 0 0 0;
  width: auto;
  z-index: 1000;
  position: relative; }

.gotop-button {
  position: relative;
  overflow: hidden;
  display: block;
  width: 70px;
  height: 70px;
  margin-left: calc(50% - 35px);
  border-radius: 50%;
  background: #ffffff url("../images/arrow-pink.png") no-repeat center center/auto 26px;
  color: #fd5f61;
  transform: rotateZ(-90deg) translateX(-35px); }
  .gotop-button .hidden-text {
    position: absolute;
    right: 100%; }

#header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #23d5c0 url("../images/e.png") no-repeat -150px center;
  background-size: 60%;
  height: 625px;
  text-align: center;
  color: #014c54;
  position: relative; }
  @media screen and (max-width: 767px) {
    #header {
      background-size: 120%;
      padding-top: 0;
      font-size: 80%; } }
  #header .wrapper {
    padding-left: 20px;
    padding-right: 20px; }
  #header p {
    font-size: 1.5em;
    margin-top: 200px; }
    @media screen and (max-width: 767px) {
      #header p {
        margin-top: 30%; } }
  #header h1 {
    font-family: Raleway-Light, Arial, sans-serif;
    font-size: 2.4rem;
    line-height: 1.9;
    margin-top: 0;
    margin-bottom: 50px; }
    @media screen and (max-width: 767px) {
      #header h1 {
        font-size: 1.5em;
        margin-top: 0;
        line-height: normal; } }
    #header h1 .title {
      font-family: Raleway-Black, Arial, sans-serif;
      font-size: 5rem; }
      @media screen and (max-width: 767px) {
        #header h1 .title {
          font-size: 1.8em; } }
  #header .home-logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  @media screen and (max-width: 767px) {
    #header .header-highlight {
      bottom: 50px; } }
  #header .btn {
    background: #14a2b0;
    color: #ffffff; }
    #header .btn:hover, #header .btn:focus {
      background: #ffffff;
      color: #14a2b0;
      transition: all 0.4s ease-in-out; }
    #header .btn:hover, #header .btn:focus {
      background: #014c54;
      -webkit-transition: background-color 300ms linear;
      -moz-transition: background-color 300ms linear;
      -o-transition: background-color 3000ms linear;
      -ms-transition: background-color 300ms linear;
      transition: background-color 300ms linear;
      color: #ffffff;
      text-decoration: none; }
  #header .fa-download {
    margin-right: 10px; }
  #header .hero-header .actionbutton {
    position: relative;
    bottom: 20px;
    overflow: hidden; }
  #header .hero-header .row {
    margin: 0; }

#menu {
  z-index: 3000;
  padding: 0;
  background-color: #ffffff;
  border-top: 1px solid #c6c6c6;
  border-bottom: 1px solid #c6c6c6; }
  #menu img {
    position: relative;
    top: -32px;
    left: 48px; }
  #menu.navbar-fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3000; }
    #menu.navbar-fixed-top .navbar {
      margin-bottom: 0; }
    @media screen and (min-width: 1025px) {
      #menu.navbar-fixed-top .menu > li > a {
        padding: 5px 10px; } }
  #menu .menu {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 1025px) {
      #menu .menu {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center; } }
    @media screen and (max-width: 1024px) {
      #menu .menu > li {
        width: 100%;
        margin: 10px 20px;
        text-align: left;
        background-color: transparent; } }
    #menu .menu > li > a {
      color: #333333;
      line-height: 40px;
      font-family: Raleway-Light, Arial, sans-serif;
      font-size: 1.8rem;
      position: relative;
      padding: 12px 2px;
      transition: none;
      transition: padding .1s ease-out; }
      @media screen and (min-width: 1025px) {
        #menu .menu > li > a {
          display: block;
          padding: 17px 10px;
          margin: 0 30px; } }
      #menu .menu > li > a::before {
        content: '/';
        font-size: 3rem;
        color: #fd5f61;
        font-family: Raleway-Black, Arial, sans-serif;
        position: absolute;
        left: -40px; }
        @media screen and (max-width: 1024px) {
          #menu .menu > li > a::before {
            display: none; } }
      #menu .menu > li > a::after {
        width: 0;
        height: 5px;
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        display: block;
        background-color: #fd5f61;
        transition: width .2s ease-in; }
        @media screen and (min-width: 1025px) {
          #menu .menu > li > a::after {
            bottom: -1px; } }
      #menu .menu > li > a:hover, #menu .menu > li > a:focus, #menu .menu > li > a.active {
        position: relative; }
        #menu .menu > li > a:hover::after, #menu .menu > li > a:focus::after, #menu .menu > li > a.active::after {
          width: 100%; }
    #menu .menu > li:first-of-type > a:before {
      display: none; }
  #menu button.navbar {
    position: relative;
    top: 20px;
    left: 20px;
    margin: 0 0 32px 0;
    padding: 0;
    overflow: visible;
    background-color: transparent;
    border: none; }
    #menu button.navbar > .icon-bar {
      display: block;
      background-color: #00E1C2;
      width: 30px;
      height: 4px;
      margin-bottom: 4px; }
    @media screen and (min-width: 1025px) {
      #menu button.navbar {
        display: none; } }
  @media screen and (max-width: 1024px) {
    #menu .main-navigation {
      max-height: 360px;
      overflow: hidden; }
      #menu .main-navigation.collapsed {
        max-height: 0; } }

#menu button.navbar {
  margin-bottom: 0; }

.mosaic .rig {
  margin: 0 auto 80px; }

.rig {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  font-size: 0;
  /* Remember to change it back to normal font size if have captions */
  list-style: none; }

.rig-item {
  display: inline-block;
  width: 25%;
  vertical-align: middle;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  animation: flipdown 0.5s ease both; }
  @media screen and (min-width: 1025px) {
    .rig-item {
      width: 33.33%;
      min-width: 324px; } }
  @media screen and (max-width: 1024px) {
    .rig-item {
      width: 50%; } }
  @media screen and (max-width: 767px) {
    .rig-item {
      width: 100%; } }
  .rig-item ul li {
    animation: intro 0.34s 0.4s cubic-bezier(1, 1.4, 0.41, 1.01) forwards; }

/* The wrapper for each item */
.rig-cell {
  margin: 10px;
  display: block;
  position: relative;
  overflow: hidden; }
  .rig-cell:hover .rig-img, .rig-cell:focus .rig-img {
    transform: scale(1.05); }
  .rig-cell:hover .rig-overlay, .rig-cell:focus .rig-overlay {
    opacity: 0.8; }
  .rig-cell:hover .rig-text, .rig-cell:focus .rig-text {
    transform: translateY(0px);
    opacity: 1; }

/* If have the image layer */
.rig-img {
  display: block;
  width: 100%;
  height: auto;
  border: none;
  transform: scale(1);
  transition: all 1s; }

/* If have the overlay layer */
.rig-overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background: #362873 url("../images/arrow-pink.png") no-repeat 93% 93%/auto 40px;
  opacity: 0;
  filter: alpha(opacity=0);
  /*For IE6 - IE8*/
  transition: all 0.6s; }

/* If have captions */
.rig-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px 20px;
  transform: translateY(-20px);
  opacity: 0;
  text-align: center;
  font-weight: normal;
  font-size: 1.8rem;
  color: #ffffff;
  transition: all .3s; }
  @media screen and (max-width: 1280px) {
    .rig-text {
      font-size: 1.6rem; } }
  @media screen and (max-width: 1024px) {
    .rig-text {
      font-size: 1.4rem; } }
  .rig-text h3 {
    margin: 0 0 .75em;
    font-family: Raleway-Medium, Arial, sans-serif;
    text-transform: uppercase;
    font-size: 2.5rem; }
    @media screen and (max-width: 1280px) {
      .rig-text h3 {
        font-size: 2rem; } }
    @media screen and (max-width: 1024px) {
      .rig-text h3 {
        font-size: 1.6rem; } }
  .rig-text p {
    margin: 0; }

@keyframes intro {
  0% {
    transform: translateY(-50%) scale(0) rotateX(10deg) rotateY(10deg) rotateZ(40deg); }
  100% {
    transform: translateY(0%) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg); } }

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg); }
  5% {
    opacity: 1; }
  80% {
    transform: rotateX(8deg); }
  83% {
    transform: rotateX(6deg); }
  92% {
    transform: rotateX(-3deg); }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg); } }

.skip-link {
  position: absolute;
  bottom: 100%;
  z-index: 1;
  background: #ffffff;
  font-size: 1.2rem; }
  .skip-link.is-visible {
    bottom: auto; }
  .skip-link p {
    display: inline-block;
    padding: .5em 0 .5em .5em; }
  .skip-link ul {
    display: inline-block; }
  .skip-link li {
    display: inline-block; }
    .skip-link li::before {
      content: "|";
      display: inline-block; }
    .skip-link li a {
      padding: .5em;
      color: inherit;
      text-decoration: underline; }
    .skip-link li:first-child::before {
      display: none; }

.title-area h2 {
  position: relative;
  margin-bottom: 80px; }
  .title-area h2:after {
    content: '';
    background-color: #fd5f61;
    display: block;
    width: 50px;
    height: 6px;
    margin: 25px auto 20px;
    position: absolute;
    bottom: -50px; }

.title-area.primary h2:after {
  background-color: #1cc6bc; }

.triangle {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0.5; }
  .triangle.top {
    border-left: 300px solid transparent;
    border-right: 300px solid transparent;
    border-bottom: 540px solid transparent; }
    .triangle.top.small {
      border-left-width: 50px;
      border-right-width: 50px;
      border-bottom-width: 85px; }
    .triangle.top.green {
      border-bottom-color: #1cc6bc; }
    .triangle.top.purple {
      border-bottom-color: #362873; }
    .triangle.top.white {
      border-bottom-color: #ffffff; }
  .triangle.down {
    border-left: 300px solid transparent;
    border-right: 300px solid transparent;
    border-top: 540px solid transparent; }
    .triangle.down.small {
      border-left-width: 50px;
      border-right-width: 50px;
      border-top-width: 85px; }
    .triangle.down.green {
      border-top-color: #1cc6bc; }
    .triangle.down.purple {
      border-top-color: #362873; }
    .triangle.down.white {
      border-top-color: #ffffff; }

@keyframes floating1 {
  0% {
    transform: scale(1.2) translateY(20px); }
  100% {
    transform: scale(0.9) translateY(-20px); } }

@keyframes floating2 {
  0% {
    transform: translateY(-20px); }
  100% {
    transform: scale(0.8) translateY(20px); } }

.section-connected {
  background: #362873 url("../images/bg-connected.jpg") no-repeat center;
  background-size: cover;
  color: #ffffff; }
  @media screen and (max-width: 1024px) {
    .section-connected {
      padding: 50px 10px; } }
  .section-connected .wrapper {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 1024px) {
      .section-connected .wrapper {
        flex-direction: column; } }
  .section-connected .title-area {
    width: 50%; }
    @media screen and (max-width: 1024px) {
      .section-connected .title-area {
        margin-top: 20px;
        width: 100%; } }
  .section-connected .api-box {
    display: none;
    left: 0;
    right: 0;
    color: #333333;
    max-width: 545px;
    padding: 80px;
    min-height: 545px;
    background-color: #ffffff; }
    .section-connected .api-box.active {
      display: block; }
    .section-connected .api-box.facebook .api-top {
      background: url("../images/bg-facebook.png") -9px -4px no-repeat; }
    .section-connected .api-box.twitter .api-top {
      background: url("../images/bg-twitter.png") no-repeat; }
    .section-connected .api-box.linkedin .api-top {
      background: url("../images/bg-linkedin.png") -8px -5px no-repeat; }
    .section-connected .api-box .active {
      z-index: 4 !important; }
    @media screen and (max-width: 767px) {
      .section-connected .api-box {
        background-position: 5px 5px; } }
    @media screen and (max-width: 1024px) {
      .section-connected .api-box {
        max-width: 100%;
        padding: 20px; } }
  .section-connected .api-top {
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 90px;
    margin-bottom: 45px;
    line-height: 1;
    font-size: 1.4rem; }
    .section-connected .api-top p {
      margin: 0; }
  .section-connected ul {
    bottom: -710px; }
    .section-connected ul li {
      width: 50px;
      height: 50px;
      display: inline;
      list-style: none;
      border: none;
      margin: 0;
      padding: 0;
      cursor: pointer; }
      .section-connected ul li a {
        font-family: Raleway-Regular, Arial, sans-serif;
        font-size: 2em; }
  .section-connected .api-switch {
    width: 100%;
    margin-top: 30px; }
    .section-connected .api-switch .api-button {
      display: inline-block;
      height: 25px;
      width: 25px;
      position: relative;
      margin: 0 20px; }
      .section-connected .api-switch .api-button:after {
        color: #74699a;
        position: absolute;
        top: 0; }
      .section-connected .api-switch .api-button:hover, .section-connected .api-switch .api-button:active, .section-connected .api-switch .api-button:focus {
        color: #74699a; }
        .section-connected .api-switch .api-button:hover:after, .section-connected .api-switch .api-button:active:after, .section-connected .api-switch .api-button:focus:after {
          color: #74699a; }
      .section-connected .api-switch .api-button.active {
        transition: all ease .5s; }
        .section-connected .api-switch .api-button.active:after {
          color: #ffffff;
          border-bottom: 5px solid;
          height: 45px; }
      .section-connected .api-switch .api-button:before {
        content: '/';
        display: inline-block;
        color: #fd5f61;
        font-family: Raleway-Black;
        font-size: 2.5rem;
        position: absolute;
        top: 0;
        left: -35px; }
      .section-connected .api-switch .api-button.twitter-api::after {
        display: inline-block;
        content: "";
        font-size: 2.5rem;
        left: 0;
        right: 0; }
      .section-connected .api-switch .api-button.facebook-api::after {
        display: inline-block;
        content: "";
        font-size: 2.5rem;
        left: 0;
        right: 0; }
      .section-connected .api-switch .api-button.facebook-api::before {
        display: none; }
      .section-connected .api-switch .api-button.linkedin-api::after {
        display: inline-block;
        content: "";
        font-size: 2.5rem;
        left: 0;
        right: 0; }
    .section-connected .api-switch .list {
      right: 0;
      padding-left: 0;
      text-align: center; }

.section-contact {
  display: flex;
  color: #ffffff;
  padding: 0; }
  @media screen and (max-width: 1024px) {
    .section-contact {
      flex-direction: column; } }
  .section-contact .form-contact {
    min-height: 750px;
    font-size: 1.8rem;
    width: 100%; }
    @media screen and (min-width: 1025px) {
      .section-contact .form-contact {
        width: 50%; } }
  .section-contact .form-contact {
    background-color: #fd5f61;
    background-size: cover; }
    .section-contact .form-contact h2 {
      margin-bottom: 30px; }
    .section-contact .form-contact button[type="submit"] {
      font-size: 1.4rem;
      padding: 15px 40px; }
    .section-contact .form-contact .wrapper {
      width: 450px;
      margin-left: 12.4%;
      padding-top: 80px; }
      .section-contact .form-contact .wrapper label[for^="wishlist"] {
        visibility: hidden; }
      @media screen and (max-width: 1024px) {
        .section-contact .form-contact .wrapper {
          width: 470px;
          margin-right: calc((100vw - 470px) / 2);
          margin-left: calc((100vw - 470px) / 2); } }
      @media screen and (max-width: 767px) {
        .section-contact .form-contact .wrapper {
          width: 100%;
          padding-right: 20px;
          padding-left: 20px;
          margin-right: auto;
          margin-left: auto; } }
    .section-contact .form-contact .placeholder {
      display: block;
      position: absolute;
      padding: 22px 0 0 20px;
      color: #ffffff;
      border: none; }
      .section-contact .form-contact .placeholder.ph-activated {
        padding: 6px 15px;
        color: #ffffff;
        font-weight: bold;
        border-radius: 0;
        width: 100%;
        font-size: 0.7em;
        transition: all 200ms ease-in-out; }
        .section-contact .form-contact .placeholder.ph-activated + input,
        .section-contact .form-contact .placeholder.ph-activated + textarea {
          font-size: 1.6rem; }
    .section-contact .form-contact .contact-submit {
      background: #ffffff;
      color: #333333;
      float: right; }
      .section-contact .form-contact .contact-submit:hover, .section-contact .form-contact .contact-submit:focus {
        background: #333333;
        color: #ffffff;
        transition: all 0.4s ease-in-out; }

.section-expert .skills-item {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  padding: 0;
  flex-wrap: wrap; }
  .section-expert .skills-item .item {
    display: block;
    list-style-type: none;
    text-align: center;
    padding: 150px 12px 12px;
    width: 210px;
    margin: 20px 5px 40px 5px;
    position: relative; }
    @media screen and (max-width: 767px) {
      .section-expert .skills-item .item {
        width: 300px; } }
    .section-expert .skills-item .item h3 {
      color: #39a4a1;
      font-weight: bold;
      font-size: 2.4rem;
      margin-bottom: 20px; }
    .section-expert .skills-item .item p {
      font-size: 1.6rem;
      line-height: 24px; }
    .section-expert .skills-item .item:before {
      color: #1cc6bc;
      font-size: 13rem !important;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%); }
    .section-expert .skills-item .item.strategy::before {
      display: inline-block;
      content: "";
      font-size: 2.2rem; }
    .section-expert .skills-item .item.conception::before {
      display: inline-block;
      content: "";
      font-size: 2.2rem; }
    .section-expert .skills-item .item.production::before {
      display: inline-block;
      content: "";
      font-size: 2.2rem; }
    .section-expert .skills-item .item.run::before {
      display: inline-block;
      content: "";
      font-size: 2.2rem; }
    .section-expert .skills-item .item.quality::before {
      display: inline-block;
      content: "";
      font-size: 2.2rem; }

.logo-techno {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 50px; }
  @media screen and (max-width: 1024px) {
    .logo-techno {
      display: block; } }

.logo-tech1,
.logo-tech2 {
  width: 49%; }
  @media screen and (max-width: 1024px) {
    .logo-tech1,
    .logo-tech2 {
      width: 100%;
      display: inline-block;
      text-align: center; } }

.slash {
  content: '';
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 8px;
  height: 100px;
  border: none;
  -o-text-overflow: clip;
  text-overflow: clip;
  background: #a6a6a6;
  -webkit-transform: skewX(-20deg) translate(-25px, 8%);
  transform: skewX(-20deg) translate(-25px, 8%);
  margin-right: 0; }
  @media screen and (max-width: 1024px) {
    .slash {
      display: none; } }

.tech1,
.tech2,
.tech3,
.tech4 {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 1024px) {
    .tech1,
    .tech2,
    .tech3,
    .tech4 {
      display: inline-block;
      text-align: center; } }
  .tech1 img,
  .tech2 img,
  .tech3 img,
  .tech4 img {
    margin: 15px; }

.section-join-us {
  background: url("../images/bg-join-us.jpg") center bottom no-repeat #000000;
  color: #ffffff;
  padding-bottom: 300px; }
  .section-join-us .recruitment-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: top;
    padding-right: 20px;
    padding-left: 20px; }
    .section-join-us .recruitment-wrapper.showing-job-offer {
      flex-direction: column; }
    @media screen and (max-width: 767px) {
      .section-join-us .recruitment-wrapper {
        margin-top: 50px;
        padding: 0;
        flex-direction: column; } }
    @media screen and (max-width: 1024px) {
      .section-join-us .recruitment-wrapper .block {
        width: 100%; } }
    .section-join-us .recruitment-wrapper .links {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px; }
      .section-join-us .recruitment-wrapper .links a {
        text-decoration: none;
        color: #ffffff;
        display: inline-block;
        border: 1px solid #ffffff;
        padding: 0.5em;
        text-align: center;
        transition: ease-in-out all 300ms; }
        .section-join-us .recruitment-wrapper .links a:hover, .section-join-us .recruitment-wrapper .links a:focus {
          color: #fd5f61;
          border-color: #fd5f61; }
    .section-join-us .recruitment-wrapper .item {
      margin-bottom: 30px;
      cursor: pointer; }
      .section-join-us .recruitment-wrapper .item .offre {
        position: relative;
        padding: 0;
        border: none;
        background: none;
        color: inherit;
        font-size: 2rem; }
        .section-join-us .recruitment-wrapper .item .offre::before {
          display: inline-block;
          content: "";
          font-size: 2rem;
          color: #fd5f61;
          margin-right: 10px; }
        @media screen and (max-width: 767px) {
          .section-join-us .recruitment-wrapper .item .offre {
            font-size: 1.5rem; } }
        .section-join-us .recruitment-wrapper .item .offre:hover, .section-join-us .recruitment-wrapper .item .offre:focus {
          text-decoration: underline; }
  .section-join-us .link-internal {
    color: inherit; }
    @media screen and (max-width: 767px) {
      .section-join-us .link-internal {
        top: -25px; } }
    @media screen and (min-width: 768px) {
      .section-join-us .link-internal {
        position: absolute; } }
  .section-join-us .item.selected .descriptif {
    width: 100%; }
  .section-join-us .item.selected .title {
    margin: 0;
    font-family: Raleway-Black, Arial, sans-serif;
    font-size: 3.5rem;
    font-weight: normal; }
  .section-join-us .item.selected .ref {
    font-style: italic; }
  .section-join-us [data-accordion-element-content] {
    height: auto;
    overflow: hidden;
    transition: height .5s;
    width: 0; }
  @media screen and (max-width: 1024px) {
    .section-join-us {
      background-size: cover; } }
  .section-join-us .expose {
    margin-bottom: 50px;
    width: 83%;
    line-height: 1.4; }
    .section-join-us .expose a {
      color: #ffffff; }
      .section-join-us .expose a:hover, .section-join-us .expose a:active, .section-join-us .expose a:focus {
        text-decoration: underline; }

.column-offer {
  display: flex;
  justify-content: space-between; }
  .column-offer .colonne {
    width: 30%; }
  .column-offer h3 {
    font-weight: 700;
    font-size: 1em;
    text-transform: uppercase;
    margin-top: 2em; }
  .column-offer a, .column-offer a.active {
    text-decoration: none;
    color: #ffffff;
    display: inline-block;
    border: 1px solid #ffffff;
    padding: 0.5em; }
  .column-offer a.job-link.override {
    border: none; }
  @media screen and (max-width: 767px) {
    .column-offer {
      flex-direction: column; }
      .column-offer .colonne {
        width: 100%; } }
  .column-offer li {
    margin: 1.6rem 0; }

.back-to-offers.link-internal {
  position: relative;
  text-align: left;
  width: 100%;
  margin-bottom: 50px;
  border: none;
  background-image: url("../images/back-arrow-pink.png"); }

.recruitment-wrapper.showing-job-offer {
  padding: 0; }

.map {
  min-height: 450px;
  font-size: 1.8rem;
  width: 100%; }
  @media screen and (min-width: 1025px) {
    .map {
      min-height: 750px;
      width: 50%; } }

.map {
  padding: 0;
  position: relative;
  background-color: #362873;
  color: #ffffff; }
  .map .city {
    margin-top: 105px;
    display: flex;
    justify-content: center;
    font-family: Raleway-Black, Arial, sans-serif;
    font-size: 3.2rem;
    font-weight: 900; }
    .map .city p {
      margin: 0; }
    .map .city button {
      width: auto;
      opacity: 0.2;
      text-decoration: underline;
      font-size: 1em;
      transition: all 0.4s ease-in-out; }
      .map .city button:hover, .map .city button:focus {
        opacity: 1; }
  .map .card {
    display: none;
    opacity: 0; }
    .map .card.selected {
      display: block;
      opacity: 1; }
  .map .mapParis {
    width: 100%;
    position: absolute;
    height: 100%;
    background: url("../images/map-paris.jpg") no-repeat right #362873;
    background-size: cover; }
  .map .mapGrenoble {
    width: 100%;
    position: absolute;
    height: 100%;
    background: url("../images/map-grenoble.jpg") no-repeat right #362873;
    background-size: cover; }
  .map .block-address {
    max-width: 470px;
    min-height: 155px;
    position: absolute;
    top: 56%;
    right: 0;
    left: 0;
    margin: 0 auto;
    padding: 28px;
    background-color: #362873;
    color: #ffffff;
    font-size: 1.6rem; }
    .map .block-address .title {
      font-family: Raleway-Black, Arial, sans-serif;
      font-size: 2.4rem;
      margin: 0; }
    .map .block-address address {
      float: left;
      font-style: normal; }
    .map .block-address .phone-mail {
      float: right; }
      @media screen and (max-width: 480px) {
        .map .block-address .phone-mail {
          width: 100%;
          float: left;
          margin-top: 10px; } }
      .map .block-address .phone-mail p {
        margin: 0; }
      .map .block-address .phone-mail a {
        color: #ffffff;
        text-decoration: underline; }
  .map .link {
    display: block;
    color: #ffffff;
    font-family: Raleway-Medium;
    text-decoration: underline;
    text-align: center;
    padding: 30px 0;
    clear: both; }
  .map .separator {
    color: #fd5f61;
    margin-right: 0.5em;
    margin-left: 0.5em;
    opacity: 0.2; }

.section-proud .wrapper {
  margin-bottom: 80px; }

.section-proud .slider .slide-item a {
  position: relative; }

.section-proud .slider .slick-slide {
  margin-right: 20px;
  height: auto; }
  .section-proud .slider .slick-slide:not(.slick-active) img {
    opacity: 0.5; }

.section-proud .slider ul.slick-dots li {
  display: none; }

.section-proud .slider .slick-arrow {
  width: 50px;
  height: 50px;
  background: url("../images/arrow-pink.png") no-repeat center center;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer; }
  .section-proud .slider .slick-arrow.slick-prev {
    transform: rotateZ(180deg);
    position: absolute;
    bottom: -100px;
    left: 0;
    overflow: hidden; }
    .section-proud .slider .slick-arrow.slick-prev span {
      position: absolute;
      left: -9999px;
      overflow: hidden; }
  .section-proud .slider .slick-arrow.slick-next {
    position: absolute;
    bottom: -100px;
    right: 0;
    overflow: hidden; }
    .section-proud .slider .slick-arrow.slick-next span {
      position: absolute;
      right: -9999px; }

.section-proud .pager {
  display: inline-block;
  position: absolute; }
  .section-proud .pager .currentSlide,
  .section-proud .pager .slideCount {
    display: inline-block;
    padding: 0 20px;
    margin: 60px 0 0; }
  .section-proud .pager .currentSlide {
    position: relative; }
    .section-proud .pager .currentSlide:after {
      content: '/';
      font-size: 3rem;
      color: #fd5f61;
      font-family: Raleway-Black, Arial, sans-serif;
      position: absolute;
      top: 50%;
      right: -15px;
      transform: translateY(-50%); }

@media screen and (max-width: 1024px) {
  .section-proud .link-internal {
    top: -25px; } }

.section-responsability {
  color: #ffffff;
  overflow: hidden;
  padding-right: 0;
  padding-left: 0;
  background-color: #200d4e;
  background-size: cover;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4); }
  .section-responsability .wrapper {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px; }
    @media screen and (max-width: 1024px) {
      .section-responsability .wrapper {
        flex-direction: column; } }
  .section-responsability .intro {
    font-size: 2.4rem;
    width: 45%;
    margin-bottom: 100px; }
    @media screen and (max-width: 1024px) {
      .section-responsability .intro {
        width: 100%;
        margin-bottom: 0; } }
  .section-responsability .ethic {
    font-size: 2rem;
    width: 45%;
    margin-top: 220px;
    margin-bottom: 100px; }
    .section-responsability .ethic p {
      margin-top: 0; }
    @media screen and (max-width: 1024px) {
      .section-responsability .ethic {
        margin-top: 20px;
        width: 100%; } }
  .section-responsability h3 {
    color: #23d5c0;
    margin-bottom: 0; }
  .section-responsability .triangle.purple {
    top: -50px;
    right: -40px; }
  .section-responsability .triangle.green {
    right: 264px;
    top: 100px;
    animation: floating1 2s alternate infinite ease-in-out; }
  .section-responsability .triangle.last {
    right: 227px;
    top: 119px;
    animation: floating2 3s alternate infinite ease-in-out; }
  .section-responsability .upside-down {
    display: block;
    position: relative;
    top: 50px;
    transform: rotate(180deg); }
