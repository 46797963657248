////
/// @file
/// PAGES : Basic page
////

.type-page {
  position: relative;

  .triangle {
    opacity: .1;

    &.green {
      animation: floating1 2s alternate infinite ease-in-out;

      &.last {
        animation: floating2 3s alternate infinite ease-in-out;
      }
    }

    &.top-right {
      &.big {
        top: 150px;
        right: -33%;
      }

      &.small {
        top: 400px;
        right: 5%;
      }

      &.last {
        top: 420px;
        right: 0;
      }
    }

    &.bottom-right {

      &.small {
        right: -7%;
        bottom: 50px;
      }

      &.last {
        right: -10%;
        bottom: 65px;
      }
    }

    &.bottom-left {

      &.small {
        top: 48%;
        left: -10%;
      }

      &.last {
        top: 50%;
        left: -5%;
      }
    }
  }
}

.legal-text {
  .title-area {
    margin-bottom: 60px;
    margin-top: 60px;
  }

  h2 a, a {
    color: $c-primary-A;
  }

  li {
    margin-left: 3em;
    margin-bottom: 12px;

    @include icon($i-arrow, 1rem) {
      color: $c-primary-A;
      margin-right: 5px;
    }
  }
}