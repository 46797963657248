////
/// @file
/// Customer page layout.
////

main {
  overflow: hidden; // Avoid horizontal scroll with animated triangles's absolute position

  &.navbar-fixed {
    margin-top: 5.5rem;
  }
}


.wrapper {
  max-width: $inner-width;
  margin: 0 auto;
  position: relative;
}


.main-content {
  h2 {
    text-transform: uppercase;
  }
}
