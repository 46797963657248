////
/// @file
/// COMPONENTS : Mosaic (Our references)
////

.mosaic {
  .rig {
    margin: 0 auto 80px;
  }
}

.rig {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  font-size: 0; /* Remember to change it back to normal font size if have captions */
  list-style: none;
}

.rig-item {
  display: inline-block;
  width: 25%;
  vertical-align: middle;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  animation: flipdown 0.5s ease both;

  @include respondTo('medium up') {
    width: 33.33%;
    min-width: 324px;
  }

  @include respondTo('medium') {
    width: 50%;
  }

  @include respondTo('small') {
    width: 100%;
  }

  ul li {
    animation: intro .34s .4s cubic-bezier(1.000, 1.400, 0.410, 1.010) forwards;
  }
}

/* The wrapper for each item */
.rig-cell {
  margin: 10px;
  display: block;
  position: relative;
  overflow: hidden;

  &:hover,
  &:focus {
    .rig-img {
      transform: scale(1.05);
    }

    .rig-overlay {
      opacity: 0.8;
    }

    .rig-text {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}

/* If have the image layer */
.rig-img {
  display: block;
  width: 100%;
  height: auto;
  border: none;
  transform: scale(1);
  transition: all 1s;
}

/* If have the overlay layer */
.rig-overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background: $c-purple-400 url('#{$path-img}arrow-pink.png') no-repeat 93% 93% / auto 40px;
  opacity: 0;
  filter: alpha(opacity=0); /*For IE6 - IE8*/
  transition: all 0.6s;
}

/* If have captions */
.rig-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px 20px;
  transform: translateY(-20px);
  opacity: 0;
  text-align: center;
  font-weight: normal;
  font-size: 1.8rem;
  color: $c-white;
  transition: all .3s;

  @include respondTo('large') {
    font-size: $fs-txt;
  }

  @include respondTo('medium') {
    font-size: 1.4rem;
  }

  h3 {
    margin: 0 0 .75em;
    font-family: $ff-medium;
    text-transform: uppercase;
    font-size: 2.5rem;

    @include respondTo('large') {
      font-size: 2rem;
    }

    @include respondTo('medium') {
      font-size: $fs-txt;
    }
  }

  p {
    margin: 0;
  }
}

@keyframes intro {
  0% {
    transform: translateY(-50%) scale(0) rotateX(10deg) rotateY(10deg) rotateZ(40deg)
  }
  100% {
    transform: translateY(0%) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
  }
}

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }
  5% {
    opacity: 1;
  }
  80% {
    transform: rotateX(8deg);
  }
  83% {
    transform: rotateX(6deg);
  }
  92% {
    transform: rotateX(-3deg);
  }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}



