////
/// @file
/// Customer skip-link
////

.skip-link {
  position: absolute;
  bottom: 100%;
  z-index: 1;
  background: $c-white;
  font-size: 1.2rem;

  &.is-visible {
    bottom: auto;
  }

  p {
    display: inline-block;
    padding: .5em 0 .5em .5em;
  }

  ul {
    display: inline-block;
  }

  li {
    display: inline-block;

    &::before {
      content: "|";
      display: inline-block;
    }

    a {
      padding: .5em;
      color: inherit;
      text-decoration: underline;
    }

    &:first-child {
      &::before {
        display: none;
      }
    }
  }
}
