.map {
  min-height: 450px;
  font-size: 1.8rem;
  width: 100%;

  @include respondTo('medium up') {
    min-height: 750px;
    width: 50%;
  }
}

.map {
  padding: 0;
  position: relative;
  background-color: $c-purple-400;
  color: $c-white;

  .city {
    margin-top: 105px;
    display: flex;
    justify-content: center;
    font-family: $ff-title;
    font-size: 3.2rem;
    font-weight: 900;

    p {
      margin: 0;
    }

    button {
      @extend %resetBtn;
      width: auto;
      opacity: 0.2;
      text-decoration: underline;
      font-size: 1em;
      transition: $transition;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  .card {
    display: none;
    opacity: 0;

    &.selected {
      display: block;
      opacity: 1;
    }
  }

  .mapParis {
    width: 100%;
    position: absolute;
    height: 100%;
    background: url("#{$path-img}map-paris.jpg") no-repeat right $c-purple-400;
    background-size: cover;
  }

  .mapGrenoble {
    width: 100%;
    position: absolute;
    height: 100%;
    background: url("#{$path-img}map-grenoble.jpg") no-repeat right $c-purple-400;
    background-size: cover;
  }

  .block-address {
    max-width: 470px;
    min-height: 155px;
    position: absolute;
    top: 56%;
    right: 0;
    left: 0;
    margin: 0 auto;
    padding: 28px;
    background-color: $c-purple-400;
    color: $c-white;
    font-size: 1.6rem;

    .title {
      font-family: $ff-title;
      font-size: 2.4rem;
      margin: 0;
    }

    address {
      float: left;
      font-style: normal;
    }

    .phone-mail {
      float: right;
      @include respondTo('xs') {
        width: 100%;
        float: left;
        margin-top: 10px;
      }

      p {
        margin: 0;
      }

      a {
        color: $c-white;
        text-decoration: underline;
      }
    }
  }

  .link {
    display: block;
    color: $c-white;
    font-family: Raleway-Medium;
    text-decoration: underline;
    text-align: center;
    padding: 30px 0;
    clear: both;
  }

  .separator {
    color: $c-primary-A;
    margin-right: 0.5em;
    margin-left: 0.5em;
    opacity: 0.2;
  }
}

