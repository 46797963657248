////
/// @file
/// Ecedi theme font variables.
////

/// Font-families
$ff-base: Raleway-Regular, Arial, sans-serif;
$ff-title: Raleway-Black, Arial, sans-serif;
$ff-light: Raleway-Light, Arial, sans-serif;
$ff-medium: Raleway-Medium, Arial, sans-serif;

$ff-icon: 'icomoon', sans-serif;

/// Font-sizes
$fs-h1:  5rem;
$fs-h2:  3.2rem;
$fs-h3:  2.8rem;
$fs-txt: 1.6rem;
