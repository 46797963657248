////
/// @file
/// COMPONENT : join us
////

.section-join-us {
  background: url("#{$path-img}bg-join-us.jpg")  center bottom no-repeat $c-black;
  color: $c-white;
  padding-bottom: 300px;

  .recruitment-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: top;
    padding-right: 20px;
    padding-left: 20px;

    &.showing-job-offer {
      flex-direction: column;
    }

    @include respondTo('small') {
      margin-top: 50px;
      padding: 0;
      flex-direction: column;

    }

    .block {
      @include respondTo('medium') {
        width: 100%;
      }
    }

    .links {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;

      a {
        text-decoration: none;
        color: $c-white;
        display: inline-block;
        border: 1px solid $c-white;
        padding: 0.5em;
        text-align: center;
        transition: ease-in-out all 300ms;

        &:hover,
        &:focus {
          color: $c-primary-A;
          border-color: $c-primary-A;
        }
      }
    }

    .item {
      margin-bottom: 30px;
      cursor: pointer;

      .offre {
        position: relative;
        padding: 0;
        border: none;
        background: none;
        color: inherit;
        font-size: 2rem;

        @include icon($i-arrow, 2rem) {
          color: $c-primary-A;
          margin-right: 10px;
        }

        @include respondTo('small') {
          font-size: 1.5rem;
        }

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  .link-internal {
    color: inherit;

    @include respondTo('small') {
      top: -25px;
    }

    @include respondTo('small up') {
      position: absolute;
    }
  }


  .item.selected {
    .descriptif {
      width: 100%;
    }

    .title {
      margin: 0;
      font-family: $ff-title;
      font-size: 3.5rem;
      font-weight: normal;
    }

    .ref {
      font-style: italic;
    }
  }

  [data-accordion-element-content] {
    height: auto;
    overflow: hidden;
    transition: height .5s;
    width: 0;
  }

  @include respondTo('medium') {
    background-size: cover;
  }

  .expose {
    margin-bottom: 50px;
    width: 83%;
    line-height: 1.4;

    a {
      color: $c-white;
      

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }

    }
  }
}

.column-offer {
  display: flex;
  justify-content: space-between;

  .colonne {
    width: 30%
  }

  h3 {
    font-weight: 700;
    font-size: 1em;
    text-transform: uppercase;
    margin-top: 2em;
  }

  a, a.active {
    text-decoration: none;
    color: $c-white;
    display: inline-block;
    border: 1px solid $c-white;
    padding: 0.5em;
  }

  a.job-link.override {
    border: none;
  }

  @include respondTo('small') {
    flex-direction: column;

    .colonne {
      width: 100%
    }
  }

  li {
    margin: 1.6rem 0;
  }
}

.back-to-offers.link-internal {
  position: relative;
  text-align: left;
  width: 100%;
  margin-bottom: 50px;
  border: none;
  background-image: url('#{$path-img}back-arrow-pink.png');
}

.recruitment-wrapper.showing-job-offer {
  padding: 0;
}

