////
/// @file
/// Customer theme base styles.
////

@include fontFace(icomoon, '#{$path-font}icomoon', normal, normal, eot woff ttf svg);
// ------------ Polices ------------
@include fontFace(Raleway-Black, '#{$path-font}Raleway-Black', normal, normal, ttf);
@include fontFace(Raleway-Bold, '#{$path-font}Raleway-Bold', normal, normal, ttf);
@include fontFace(Raleway-SemiBold, '#{$path-font}Raleway-SemiBold', normal, normal, ttf);
@include fontFace(Raleway-Medium, '#{$path-font}Raleway-Medium', normal, normal, ttf);
@include fontFace(Raleway-Regular, '#{$path-font}Raleway-Regular', normal, normal, ttf);
@include fontFace(Raleway-Italic, '#{$path-font}Raleway-Italic', normal, normal, ttf);
@include fontFace(Raleway-Light, '#{$path-font}Raleway-Light', normal, normal, ttf);

/// Better width/padding handling thanks to box-sizing.
/// @see https://css-tricks.com/international-box-sizing-awareness-day/
/// Uses inheritance so it can easily be overridden for specific components.
/// @see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*,
*::before,
*::after {
  box-sizing: inherit;
}

/// Base font-size for html equates to 10px : allows using rem font-sizes everywhere by dividing / 10.
/// Ex : 2rem will equate to 20px.

html {
  font-size: 0.625em;
  box-sizing: border-box;
}

body {
  font-family: $ff-base;
  font-size: $fs-txt;
  margin: 0;
  overflow-x: hidden;
}

h1,
h2,
h3 {
  font-family: $ff-title;
  font-weight: bold;
}

h2 {
  font-size: $fs-h2;
}

h3 {
  font-size: $fs-h3;
}

a {
  text-decoration: none;
  transition: $transition;
}

// -------------------- Lists --------------------
ul,
ol,
li {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

section {
  position: relative;
  padding: 50px 20px;
  min-height: 745px;
}

button {
  font-family: inherit;
  cursor: pointer;
}
