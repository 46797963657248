////
/// @file
/// COMPONENT : Contact
////

.section-contact {
  display: flex;
  color: $c-white;
  padding: 0;
  @include respondTo('medium') {
    flex-direction: column;
  }

  .form-contact {
    min-height: 750px;
    font-size: 1.8rem;
    width: 100%;
    @include respondTo('medium up') {
      width: 50%;
    }
  }

  .form-contact {
    background-color: $c-primary-A;
    background-size:cover;

    h2 {
      margin-bottom: 30px;
    }

    button[type="submit"] {
      font-size: 1.4rem;
      padding: 15px 40px;
    }

    .wrapper {
      width: 450px;
      margin-left: 12.4%;
      padding-top: 80px;
      label[for^="wishlist"]{
        visibility: hidden;
      }

      @include respondTo('medium') {
        width: 470px;
        margin-right: calc((100vw - 470px) / 2);
        margin-left: calc((100vw - 470px) / 2);
      }

      @include respondTo('small') {
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    .placeholder {
      display: block;
      position: absolute;
      padding: 22px 0 0 20px;
      color: $c-white;
      border: none;

      &.ph-activated {
        padding: 6px 15px;
        color: $c-white;
        font-weight: bold;
        border-radius: 0;
        width: 100%;
        font-size: 0.7em;
        transition: all 200ms ease-in-out;

        &+ input,
        &+ textarea{
          font-size: 1.6rem;
        }
      }
    }

    .contact-submit {
      @include btnColor($c-white, $c-text-default);
      float: right;
    }
  }

  .triangle {

  }
}
