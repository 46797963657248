////
/// @file
/// COMPONENT : responsability
////

.section-responsability {

  color: $c-white;
  overflow: hidden;
  padding-right: 0;
  padding-left: 0;
  background-color: $c-purple-900;
  background-size: cover;
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.4);

  .wrapper {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;

    @include respondTo('medium') {
      flex-direction: column;
    }
  }

  .intro {
    font-size: 2.4rem;
    width: 45%;
    margin-bottom: 100px;

    @include respondTo('medium') {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .ethic {
    font-size: 2rem;
    width: 45%;
    margin-top: 220px;
    margin-bottom: 100px;

    p {
      margin-top: 0;
    }

    @include respondTo('medium') {
      margin-top: 20px;
      width: 100%;
    }
  }

  h3 {
    color: $c-green-100;
    margin-bottom: 0;
  }

  .triangle {

    &.purple {
      top: -50px;
      right: -40px;
    }

    &.green {
      right: 264px;
      top: 100px;
      animation: floating1 2s alternate infinite ease-in-out;
    }

    &.last {
      right: 227px;
      top: 119px;
      animation: floating2 3s alternate infinite ease-in-out;
    }
  }
  .upside-down {
    display: block;
    position: relative;
    top:50px;
    transform: rotate(180deg);
  }
}

