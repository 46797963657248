////
/// @file
/// PAGES : Legal text
////

.legal-text {
  margin-bottom: 150px;
  padding-right: 9%;
  padding-left: 9%;
  position: relative;

  @include respondTo('medium up') {
    max-width: 930px;
    padding-right: 0;
  }

}

.cookie-item {
  margin-bottom: 25px;
  p {
    margin: 0;
  }
}

.legal-notices-matomo-opt-out {
  > p {
    &:first-of-type {
      display: none;
    }
  }

  strong {
    font-weight: 400;
  }
}
