////
/// @file
/// COMPONENT : Title sections custom styles
////

.title-area {
  h2 {
    @include separator($c-primary-A, 6px, 25px auto 20px);

    position: relative;
    margin-bottom: 80px;

  }

  &.primary {
    h2 {
      &:after {
        background-color: $c-primary-B;
      }
    }
  }

}
