////
/// @file
/// Global variables.
////

$site-max-width: 100%;
$inner-width: 1235px;

$transition: all 0.4s ease-in-out;

$path-font: '../fonts/';
$path-img:  '../images/';

/// Default large screen size.
$screen-large:  1280px;
/// Default medium screen size.
$screen-medium: 1024px;
/// Default small screen size.
$screen-small:   767px;
/// Default xsmall screen size.
$screen-xsmall:  480px;

/// A map of media query expressions keyed by their breakpoint name.
/// @see respondTo
$breakpoints: (

  'xs': (
    max-width: #{$screen-xsmall},
  ),
  'small': (
    max-width: #{$screen-small},
  ),
  'medium': (
    max-width: #{$screen-medium},
  ),
  'large': (
      max-width: #{$screen-large},
  ),

  'xs to small': (
    min-width: #{$screen-xsmall} + 1px,
    max-width: #{$screen-small},
  ),
  'small to medium': (
    min-width: #{$screen-small} + 1px,
    max-width: #{$screen-medium},
  ),
  'medium to large': (
    min-width: #{$screen-medium} + 1px,
    max-width: #{$screen-large},
  ),

  'xs up': (
    min-width: #{$screen-xsmall} + 1px,
  ),
  'small up': (
    min-width: #{$screen-small} + 1px,
  ),

  'medium up': (
    min-width: #{$screen-medium} + 1px,
  ),
  'large up': (
    min-width: #{$screen-large} + 1px,
  ),

);
